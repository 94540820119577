import React from 'react';
import { Redirect } from 'react-router-dom';

class Quiz extends React.Component {
    render () {

    return (
      this.props.auth.isAuthenticated ?
      (<div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '90vh'
        }}
      >
        <h1>Quiz Coming Soon</h1>
      </div>) : (
           <Redirect to={{ pathname: "/sign-in"
             }} 
             />
        )
    );
  };
}
  
  export default Quiz;