import React from 'react';
import "./common/Cpc.css"


class ChangePasswordConfirmation extends React.Component {
    render() {
        return(
            <section className="container-fluid" >
            <section className="row justify-content-center">
            <section className="col-12 col-sm-8 col-md-6">
            <div className="container cp-container">
            <h1>Change Password</h1>
            <p className="col-12 col-sm-8 col-lg-8">Your password has been successfully updated!</p> 
            </div>
            </section>
            </section>
            </section>
        );
    }
}

export default ChangePasswordConfirmation;