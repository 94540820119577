import React, { Component } from "react";
import Validate from "../components/common/FormValidation";
import FormErrors from "../components/common/FormErrors";
import { Auth } from "aws-amplify";
import {Link} from 'react-router-dom';
import '../components/common/Login.css';



export default class Login extends Component {
    state = {
        username: "",
        password: "",
        errors: {
            cognito: null,
            blankfield: false,
        }

    };

    clearErrorState = () => {
        this.setState({
            errors: {
                cognito: null,
                blankfield: false
            }
        });
    };

    handleSubmit = async event => {
        event.preventDefault();
        this.clearErrorState();
        const error = Validate(event, this.state);
        if (error) {
            this.setState({
                errors: { ...this.state.errors, ...error }
            });
        }

        // AWS Cognito integration here
        try {
            const user = await Auth.signIn(this.state.username, this.state.password );
            console.log(user);
            this.props.auth.setAuthStatus(true);
            this.props.auth.setUser(user);
            this.props.history.push("/Home");
        } catch (error) {
            let err = null;
            !error.message ? err = { "message": error } : err = error;
            console.log(err);
            this.setState({
                errors: {
                    ...this.state.errors,
                    cognito: error
                }
            })
        }
    };

    onInputChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
        document.getElementById(event.target.id).classList.remove("is-danger");
    };

    render() {
        return (
            
            <section className="container-fluid" >
                <section className="row justify-content-center">
                <section className="col-12 col-sm-6 col-md-3">
                <FormErrors formerrors={this.state.errors} />
                <form className="form-container bg" onSubmit={this.handleSubmit}>
                <span className="h3">Login</span>
                    <div className="form-group">
                        <label >Email or Username</label>
                        <input type="text" className="form-control" id="username" placeholder="Email or Username" value={this.state.username} onChange={this.onInputChange} />
                    </div>

                    <div className="form-group">
                        <label >Password</label>
                        <input type="password" className="form-control" id="password" placeholder="Password" value={this.state.password} onChange={this.onInputChange} />
                    </div>

                    <div className="form-group">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                            <label className="custom-control-label" htmlFor="customCheck1" >Remember me</label>
                        </div>
                    </div>

                    <button type="submit" className="bt btn-dark btn-block btn-lg" >Sign in</button>
                    <p className="forgot-password text-left" >
                        <Link to="/ForgotPassword"> Forgot password? </Link>
                    </p>
                </form>
                </section>
                </section>
            </section>
        );
    }
}