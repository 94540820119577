import React, {useState} from 'react';
import { NavLink } from 'react-router-dom';
import "./Button.css";
import '../common/NavBar.css';
import { Auth } from 'aws-amplify';

function Navbar(props) {
    const [click, setClick] = useState(false);
  
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const handleLogOut = async event => {
        
      try {
          const msg = Auth.signOut();
          console.log(msg);
          props.auth.setAuthStatus(false);
          props.auth.setUser(null);
      } catch (error) {
         console.log(error.message);
      }
     };
  

    return (
      <>
        <nav className='navbar'>
          <NavLink to='/' className='navbar-logo' onClick={closeMobileMenu}>
          <img src={require("./footer-logo.png")} alt="RTCloud" />
          </NavLink>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
            { props.auth.isAuthenticated && (<NavLink to='/Home' className='nav-links' onClick={closeMobileMenu} activeStyle={{ fontWeight: "bold", color: "orange" }}>
              Home
              </NavLink>)}
            </li>
            <li className='nav-item'>
            { props.auth.isAuthenticated && (<NavLink
                to='/Courses'
                className='nav-links'
                activeStyle={{ fontWeight: "bold", color: "orange" }}
                onClick={closeMobileMenu}>
                Courses
              </NavLink>)}
            </li>
            <li className='nav-item' >
              { props.auth.isAuthenticated && (<NavLink
                to='/Quiz'
                className='nav-links'
                activeStyle={{ fontWeight: "bold", color: "orange" }}
                onClick={closeMobileMenu}>
                Quiz
              </NavLink>)}
            </li>
            <li>
            <div> 
            <p style={{ color: "orange", align: "center", padding: "5px", marginTop: "20px"}}>
               { props.auth.isAuthenticated && props.auth.user && ('Hello '+ props.auth.user.username)} </p>
               </div>   
            <NavLink
              to='/sign-in'
              className='nav-links-mobile'
              onClick={closeMobileMenu && handleLogOut}
            >
            { props.auth.isAuthenticated ? 'Logout' : 'Login' }
            </NavLink>
          </li>
          </ul>
          <NavLink to='/sign-in'
           onClick={closeMobileMenu && handleLogOut}
          >
         <button className='btn'>
          { props.auth.isAuthenticated ? 'Logout' : 'Login'}
        </button>
        </NavLink>
        </nav>
      </>
    );
  }
  
  export default Navbar;