import React from 'react';
import { Redirect } from 'react-router-dom';

export default class python extends React.Component {
    render() {
    return (
        this.props.auth.isAuthenticated ?
        (<section className="Container">
        <div>
            <h2 align="center">Python Course Coming Soon</h2>
        </div>
        </section>) : (
           <Redirect to={{ pathname: "/sign-in" }} />)
    )
}
}
