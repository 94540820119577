export default [
    {
        id: 1,
        link: "https://login.globalcloudsolutions.org/static/media/videos2/vpcclass5.mp4",
        title: "VPC Class 5",
        duration: "0:52:37"
    },
    {
        id: 2,
        link: "https://login.globalcloudsolutions.org/static/media/videos2/vpcclass4.mp4'",
        title: "VPC Class 6"
    },
    {
        id: 3,
        link: "https://login.globalcloudsolutions.org/static/media/videos2/vpcclass5.mp4",
        title: "VPC Class 7",
        duration: "1:02:37"

    },
    {
        id: 4,
        link: "https://login.globalcloudsolutions.org/static/media/videos2/vpcclass4.mp4'",
        title: "VPC Class 8",
        duration: "0:59:37"
    },
    {
        id: 5,
        link: "https://login.globalcloudsolutions.org/static/media/videos2/vpcclass4.mp4'",
        title: "VPC Class 9",
        duration: "0:49:37"
    }
    
]