import React, { Component } from "react";
import Validate from "../components/common/FormValidation";
import FormErrors from "../components/common/FormErrors";
import { Auth } from "aws-amplify";
import '../components/common/Login.css';



export default class SignUp extends Component {
    state = {
        username: "",
        email: "",
        password: "",
        confirmpassword: "",
        errors: {
            cognito: null,
            blankfield: false,
            passwordmatch: false
        }

    }

    clearErrorState = () => {
        this.setState({
            errors: {
                cognito: null,
                blankfield: false,
                passwordmatch: false
            }
        });
    }

    handleSubmit = async event => {
        event.preventDefault();

        // Form validation
        this.clearErrorState();
        const error = Validate(event, this.state);
        if (error) {
            this.setState({
                errors: { ...this.state.errors, ...error }
            });
        }
        //Cognito
        const { username, email, password } = this.state;
        try {
          const signUpResponse = await Auth.signUp({
            username,  
            password,
              attributes: {
                  email: email
              }
          });
          console.log(signUpResponse);
          this.props.history.push("/Welcome"); 
        }catch(error) {
            let err = null;
            !error.message ? err = { "message": error} : err = error;
            console.log(err);
            this.setState({
              errors: {
                ...this.state.errors,
                cognito: error  
              }
            })
        }
    };
    
    onInputChange = event => {
        this.setState({
          [event.target.id]: event.target.value
        });
        document.getElementById(event.target.id).classList.remove("is-danger");
    };

    render() {
        return (
                <div className="container">
                    <FormErrors formerrors={this.state.errors} />
                    <form onSubmit={this.handleSubmit}>
                        <h3>Register</h3>
                        <p>Password minimum requirements, not less than 8 characters, One Special Character($@! etc...), One Upper Case Letter and one Number </p>
                        <div className="form-group">
                            <label>Username</label>
                            <input type="text" className="form-control" id="username" placeholder="Enter Username" value={this.state.username} onChange={this.onInputChange}/>
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input type="email" className="form-control" id="email" placeholder="Enter email" value={this.state.email} onChange={this.onInputChange}/>
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input type="password" className="form-control" id="password" placeholder="Enter password" value={this.state.password} onChange={this.onInputChange}/>
                        </div>
                        <div className="form-group">
                            <label>Confirm Password</label>
                            <input type="password" className="form-control" id="confirmpassword" placeholder="Confirm password" value={this.state.confirmpassword} onChange={this.onInputChange}/>
                        </div>
                        <button type="submit" className="btn btn-dark btn-lg btn-block">Register</button>
                        <p className="forgot-password text-right">
                            Already registered <a href="/sign-in">log in?</a>
                        </p>
                    </form>
                </div>
            );
        }
    }
