import React from 'react';

export default function Welcome() {
    return (
      <div >
      <h1 align="center">Welcome!</h1>
      <p align="center">You have successfully registered a new account. </p>
      <p align="center">You will receive a verification email, Click on the link to Verify your Account.</p>
      </div>
    );
}