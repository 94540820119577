import React from 'react';
import Validate from "../components/common/FormValidation";
import FormErrors from "../components/common/FormErrors";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import '../components/common/Login.css';


class ForgotPassword extends React.Component {
    state = {
      email: "",
      errors: {
         cognito: null,
         blankfield: false 
      }  
    }

    clearErrorState = () => {
        this.setState({
            errors: {
                cognito: null,
                blankfield: false
            }
        });
    }

    forgotPasswordHandler = async event => {
        event.preventDefault();
    
        // Form validation
        this.clearErrorState();
        const error = Validate(event, this.state);
        if (error) {
          this.setState({
            errors: { ...this.state.errors, ...error }
          });
        }
    
        // AWS Cognito integration here
        try {
          await Auth.forgotPassword(this.state.email);
          this.props.history.push('/ForgotPasswordVerification');
        }catch(error) {
          console.log(error);
        }
    }

    onInputChange = event => {
        this.setState({
          [event.target.id]: event.target.value
        });
        document.getElementById(event.target.id).classList.remove("is-danger");
    }

    render() {
        return (
          <section className="container-fluid" >
                <section className="row justify-content-center">
                <section className="col-12 col-sm-6 col-md-3">
            <div className="form-container">
                    <FormErrors formerrors={this.state.errors} />
                    <form onSubmit={this.forgotPasswordHandler}>
                        <h3>Forgot Your Password </h3>
                        <div className="form-group">
                            <label>Please enter the email address associated with your account and we'll
            email you a password reset link.</label>
                            <input type="email" className="form-control" id="email" placeholder="Enter email" value={this.state.email} onChange={this.onInputChange}/>
                        </div>
                        <button type="submit" className="bt btn-dark btn-block btn-lg">Submit</button>
                        <p className="forgot-password text-right">
                            Already registered <Link to="/sign-in">log in?</Link>
                        </p>
                    </form>
                </div>
                </section>
                </section>
                </section>
        );
    }
}

export default ForgotPassword;