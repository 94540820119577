import React from "react";
import { Link } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import '../components/common/about.css';


class AwsHome extends React.Component {
    render () {
    return (
        this.props.auth.isAuthenticated ?
        (<div className="about">
        <div className="container">
        <div className="jumbotron">
        <h3 className="display-4">RTCloud AWS Solutions Architect Class Second Session</h3>
        <p className="lead">Jan. 30th 2021 - Apr 30 2021.</p>
        <p className="lead">Class Schedules.</p>
        <p>Thursdays 9pm - 10pm CST</p>
        <p>Saturdays 10am - 11a CST</p>
        <p>Sundays 9pm - 10pm CST</p>
        <p>Class videos will be made available to watch on the portal 24 hours after each class, Click on the link below to view</p>
        <hr className="my-4" />
        <Link to="/Aws1" className="btn-dark btn-lg">View Class</Link>
      </div>
      <div className="jumbotron">
        <h3 className="display-4">RTCloud Solutions AWS Solutions Architect Class First Session</h3>
        <p className="lead">Sep. 2020 - Dec 2020.</p>
        <hr className="my-4" />
        <Link to="/Aws" className="btn-dark btn-lg">View Class</Link>
      </div>
        </div> 
      </div>) : (
           <Redirect to={{ pathname: "/sign-in", 
             }} 
             />
        )
    );
}
}

export default AwsHome;