import React from 'react';
import { Redirect } from 'react-router-dom';
import './common/Video.css';
import "../../node_modules/video-react/dist/video-react.css";
import { Player, ControlBar, ReplayControl, ForwardControl, PlaybackRateMenuButton } from 'video-react';

class Aws extends React.Component {
    render () {
    return (
        this.props.auth.isAuthenticated ?
        (<div className="about">
            <div className="section_title text-center"><h2>Course: AWS Solutions Architect</h2></div>
        {/* <div className="container">
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6">
              <div className="about_content">
			  <Player
                playsInline
                preload
                aspectRatio="auto"
                poster={require('./common/cover.png')}
                src='https://login.globalcloudsolutions.org/static/media/videos/IAMClass1.mp4'
                    >
      <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
                    </Player>

              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">IAM Class 1</h4>
              </div>
              </div>   
              </div>
            </div>
            <div className="col-lg-6">
			<Player
            playsInline
            preload
            aspectRatio="auto"
            poster={require('./common/cover.png')}
            src='https://login.globalcloudsolutions.org/static/media/videos/IAMClass2.mp4'
            >
             <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
        </Player> 
        <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">IAM Class 2</h4>
              </div>
              </div>
		</div>
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6 order-lg-1 order-2">
            <Player
                playsInline
                preload
                aspectRatio="auto"
                poster={require('./common/cover.png')}
                src='https://login.globalcloudsolutions.org/static/media/videos/IAMClass3.mp4'
                    >
                <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
                  </Player> 
                  <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">IAM Class 3</h4>
              </div>
              </div>   
               </div>
            <div className="col-lg-6 order-lg-2 order-1">
              <div className="about_content">
              <Player
                playsInline
                preload
                aspectRatio="auto"
                poster={require('./common/cover.png')}
                src='https://login.globalcloudsolutions.org/static/media/videos/S3Class1.mp4'
                    >
                    <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
                  </Player> 
                  <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">S3 Class 1</h4>
              </div>
              </div>     
              </div>
            </div>
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6 order-lg-1 order-2">
            <Player
                playsInline
                preload
                aspectRatio="auto"
                poster={require('./common/cover.png')}
                src='https://login.globalcloudsolutions.org/static/media/videos/S3Class2.mp4'
                    >
                    <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
                  </Player> 
                  <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">S3 Class 2</h4>
              </div>
              </div>
               </div>
            <div className="col-lg-6 order-lg-2 order-1">
              <div className="about_content">
              <Player
                playsInline
                preload
                aspectRatio="auto"
                poster={require('./common/cover.png')}
                src='https://login.globalcloudsolutions.org/static/media/videos/S3Class3.mp4'
                    >
                    <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
                  </Player> 
                  <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">S3 Class 3</h4>
              </div>
              </div> 
              </div>
            </div>
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6 order-lg-1 order-2">
            <Player
                playsInline
                preload
                aspectRatio="auto"
                poster={require('./common/cover.png')}
                src='https://login.globalcloudsolutions.org/static/media/videos/S3Class4.mp4'
                    >
                    <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
                  </Player> 
                  <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">S3 Class 4</h4>
              </div>
              </div>
               </div>
            <div className="col-lg-6 order-lg-2 order-1">
              <div className="about_content">
              <Player
              playsInline
              preload
              aspectRatio="auto"
              poster={require('./common/cover.png')}
              src='https://login.globalcloudsolutions.org/static/media/videos/S3CloudFrontClass1.mp4'
                >
           <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
              </Player> 
              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">S3 CloudFront Class 1</h4>
              </div>
              </div>
              </div>
            </div>
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6 order-lg-1 order-2">
            <Player
              playsInline
              preload
              aspectRatio="auto"
              poster={require('./common/cover.png')}
              src='https://login.globalcloudsolutions.org/static/media/videos/S3CloudFrontClass2.mp4'
                >
           <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
              </Player> 
              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">S3 CloudFront Class 2</h4>
              </div>
              </div>
               </div>
            <div className="col-lg-6 order-lg-2 order-1">
              <div className="about_content">
              <Player
              playsInline
              preload
              aspectRatio="auto"
              poster={require('./common/cover.png')}
              src='https://login.globalcloudsolutions.org/static/media/videos/EC2Class1.mp4'
                >
           <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
              </Player> 
              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">EC2 Class 1</h4>
              </div>
              </div>
              </div>
            </div>
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6 order-lg-1 order-2">
            <Player
              playsInline
              preload
              aspectRatio="auto"
              poster={require('./common/cover.png')}
              src='https://login.globalcloudsolutions.org/static/media/videos/EC2Class2.mp4'
                >
           <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
              </Player> 
              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">EC2 Class 2</h4>
              </div>
              </div>
               </div>
            <div className="col-lg-6 order-lg-2 order-1">
              <div className="about_content">
              <Player
              playsInline
              preload
              aspectRatio="auto"
              poster={require('./common/cover.png')}
              src='https://login.globalcloudsolutions.org/static/media/videos/EC2Class3.mp4'
                >
           <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
              </Player> 
              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">EC2 Class 3</h4>
              </div>
              </div>
              </div>
            </div>
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6 order-lg-1 order-2">
            <Player
              playsInline
              preload
              aspectRatio="auto"
              poster={require('./common/cover.png')}
              src='https://login.globalcloudsolutions.org/static/media/videos/EC2Class4.mp4'
                >
           <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
              </Player> 
              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">EC2 Class 4</h4>
              </div>
              </div>
               </div>
            <div className="col-lg-6 order-lg-2 order-1">
              <div className="about_content">
              <Player
              playsInline
              preload
              aspectRatio="auto"
              poster={require('./common/cover.png')}
              src='https://login.globalcloudsolutions.org/static/media/videos/EC2Class5.mp4'
                >
           <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
              </Player> 
              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">EC2 Class 5</h4>
              </div>
              </div>
              </div>
            </div>
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6 order-lg-1 order-2">
            <Player
              playsInline
              preload
              aspectRatio="auto"
              poster={require('./common/cover.png')}
              src='https://login.globalcloudsolutions.org/static/media/videos/EC2Class6.mp4'
                >
           <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
              </Player> 
              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">EC2 Class 6</h4>
              </div>
              </div>
               </div>
            <div className="col-lg-6 order-lg-2 order-1">
              <div className="about_content">
              <Player
              playsInline
              preload
              aspectRatio="auto"
              poster={require('./common/cover.png')}
              src='https://login.globalcloudsolutions.org/static/media/videos/EC2Class7.mp4'
                >
           <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
              </Player> 
              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">EC2 Class 7</h4>
              </div>
              </div>
              </div>
            </div>
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6 order-lg-1 order-2">
            <Player
              playsInline
              preload
              aspectRatio="auto"
              poster={require('./common/cover.png')}
              src='https://login.globalcloudsolutions.org/static/media/videos/EC2Class8.mp4'
                >
           <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
              </Player> 
              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">EC2 Class 8</h4>
              </div>
              </div>
               </div>
            <div className="col-lg-6 order-lg-2 order-1">
              <div className="about_content">
              <Player
              playsInline
              preload
              aspectRatio="auto"
              poster={require('./common/cover.png')}
              src='https://login.globalcloudsolutions.org/static/media/videos/EC2Class9.mp4'
                >
           <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
              </Player> 
              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">EC2 Class 9</h4>
              </div>
              </div>
              </div>
            </div>
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6 order-lg-1 order-2">
            <Player
              playsInline
              preload
              aspectRatio="auto"
              poster={require('./common/cover.png')}
              src='https://login.globalcloudsolutions.org/static/media/videos/EC2Class10.mp4'
                >
           <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
              </Player> 
              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">EC2 Class 10</h4>
              </div>
              </div>
               </div>
            <div className="col-lg-6 order-lg-2 order-1">
              <div className="about_content">
              <Player
              playsInline
              preload
              aspectRatio="auto"
              poster={require('./common/cover.png')}
              src='https://login.globalcloudsolutions.org/static/media/videos/EC2Class11.mp4'
                >
           <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
              </Player> 
              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">EC2 Class 11</h4>
              </div>
              </div>
              </div>
            </div>
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6 order-lg-1 order-2">
            <Player
              playsInline
              preload
              aspectRatio="auto"
              poster={require('./common/cover.png')}
              src='https://login.globalcloudsolutions.org/static/media/videos/VPCClass1.mp4'
                >
           <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
              </Player> 
              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">VPC  Class 1</h4>
              </div>
              </div>
               </div>
            <div className="col-lg-6 order-lg-2 order-1">
              <div className="about_content">
               <Player
              playsInline
              preload
              aspectRatio="auto"
              poster={require('./common/cover.png')}
              src='https://login.globalcloudsolutions.org/static/media/videos/VPCClass2.mp4'
                >
           <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
              </Player> 
              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">VPC  Class 2</h4>
              </div>
              </div>
              </div>
            </div>
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6 order-lg-1 order-2">
            <Player
              playsInline
              preload
              aspectRatio="auto"
              poster={require('./common/cover.png')}
              src='https://login.globalcloudsolutions.org/static/media/videos/VPCClass3.mp4'
                >
           <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
              </Player> 
              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">VPC  Class 3</h4>
              </div>
              </div>
               </div>
               <div className="col-lg-6 order-lg-2 order-1">
              <div className="about_content">
               <Player
              playsInline
              preload
              aspectRatio="auto"
              poster={require('./common/cover.png')}
              src='https://login.globalcloudsolutions.org/static/media/videos/VPCClass4.mp4'
                >
           <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
              </Player> 
              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold ">VPC  Class 4</h4>
              </div>
              </div>
              </div>
            </div>
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6 order-lg-1 order-2">
            <Player
              playsInline
              preload
              aspectRatio="auto"
              poster={require('./common/cover.png')}
              src='https://login.globalcloudsolutions.org/static/media/videos/VPCClass5.mp4'
                >
           <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
              </Player> 
              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">VPC  Class 5</h4>
              </div>
              </div>
               </div>
               <div className="col-lg-6 order-lg-2 order-1">
              <div className="about_content">
               <Player
              playsInline
              preload
              aspectRatio="auto"
              poster={require('./common/cover.png')}
              src='https://login.globalcloudsolutions.org/static/media/videos/VPCClass6.mp4'
                >
           <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
              </Player> 
              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold ">VPC  Class 6/Route 53</h4>
              </div>
              </div>
              </div>
            </div>
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6 order-lg-1 order-2">
            <Player
              playsInline
              preload
              aspectRatio="auto"
              poster={require('./common/cover.png')}
              src='https://login.globalcloudsolutions.org/static/media/videos/VPCClass7.mp4'
                >
           <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
              </Player> 
              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">VPC  Class 7/Application LoadBalancer</h4>
              </div>
              </div>
               </div>
               <div className="col-lg-6 order-lg-2 order-1">
              <div className="about_content">
               <Player
              playsInline
              preload
              aspectRatio="auto"
              poster={require('./common/cover.png')}
              src='https://login.globalcloudsolutions.org/static/media/videos/AssignmentReview.mp4'
                >
           <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
              </Player> 
              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold ">Assignment/Project Review</h4>
              </div>
              </div>
              </div>
            </div>
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6 order-lg-1 order-2">
            <Player
              playsInline
              preload
              aspectRatio="auto"
              poster={require('./common/cover.png')}
              src='https://login.globalcloudsolutions.org/static/media/videos/AssignmentReview2.mp4'
                >
           <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
              </Player> 
              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">Assignment/Project Review 2</h4>
              </div>
              </div>
               </div>
               <div className="col-lg-6 order-lg-2 order-1">
              <div className="about_content">
               <Player
              playsInline
              preload
              aspectRatio="auto"
              poster={require('./common/cover.png')}
              src='https://login.globalcloudsolutions.org/static/media/videos/AssignmentReview3.mp4'
                >
           <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
              </Player> 
              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold ">Assignment/Project Review 3</h4>
              </div>
              </div>
              </div>
            </div>
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6 order-lg-1 order-2">
            <Player
              playsInline
              preload
              aspectRatio="auto"
              poster={require('./common/cover.png')}
              src='https://login.globalcloudsolutions.org/static/media/videos/AssignmentReview4.mp4'
                >
           <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
              </Player> 
              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">Assignment/Project Review 4(Assume Role)</h4>
              </div>
              </div>
               </div>
               <div className="col-lg-6 order-lg-1 order-2">
            <Player
              playsInline
              preload
              aspectRatio="auto"
              poster={require('./common/cover.png')}
              src='https://login.globalcloudsolutions.org/static/media/videos/ResumeReview.mp4'
                >
           <ControlBar autoHide={false}>
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <ReplayControl seconds={10} order={2.2} />
                    <ForwardControl seconds={10} order={3.2} />
                  </ControlBar>  
              </Player> 
              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">Resume Review</h4>
              </div>
              </div>
               </div>
          </div>
        </div> */}
      </div>
      ) : (
       <Redirect to={{ pathname: "/sign-in", 
         }} 
         />
    )
    );
}
}

export default Aws;