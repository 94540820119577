import React from 'react';
import { Redirect } from 'react-router-dom';

class Grades extends React.Component {
  render () {
    return (
      this.props.auth.isAuthenticated ?
      (<div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '90vh'
        }}
      >
        <h1>View Your Grades</h1>
      </div>) : (
           <Redirect to={{ pathname: "/sign-in"
             }} 
             />
        )
    );
  }
}
  
  export default Grades;