import React from 'react';
import '../components/common/courses.css';
import { Link, Redirect } from 'react-router-dom';


class Courses extends React.Component {
    render() {
        return (
            this.props.auth.isAuthenticated ?
            (<div className="courses">
            <div className="container">
            <div className="col-lg-10 offset-lg-1">
              <div className="section_title text-center"><h2>What are you going to learn?</h2></div>
              <div className="section_subtitle">The aim of this course is to equip you with the knowledge across a number of different key areas, which have been defined by AWS as being able to:
                effectively demonstrate knowledge of how to architect and deploy secure and robust applications on AWS technologies,
                define a solution using architectural design principles based on customer requirements
                and provide implementation guidance based on best practices to the organization throughout the life cycle of any projects
              </div>
            </div>
              {/* Featured Course */}
            
              <div className="row courses_row">
                {/* Course */}
                <div className="col-lg-4 col-md-6">
                  <div className="course">
                    <div className="course_image"><img src={require("./common/aws.jpg")} alt="" /></div>
                    <div className="course_body">
                      <div className="course_header d-flex flex-row align-items-center justify-content-start">
                        <div className="course_tag"><Link to="/AwsHome">Go To Course</Link></div>
                        <div className="course_price ml-auto"><span /></div>
                      </div>
                      <div className="course_title"><h3><Link to="/AwsHome">AWS Solutions Architect</Link></h3></div>
                      <div className="course_text">A career in AWS Solutions Architect (SA) will mean you use your skills and expertise to help develop advanced cloud-based solutions and migrate existing workloads to the cloud. Most legacy&nbsp; companies running their applications on-premise are in constant serch fro experts to design, depoly and migrate exsiting workloads to the Cloud. Being an AWS Solutions Architect cannot be far fetched for beginners.
                      </div>
                      <div className="course_footer d-flex align-items-center justify-content-start">
                        <div id="messages">
                        </div>
                        <div className="course_author_image"><img src alt="" /></div>
                        <div className="course_author_name"></div>
                        <div className="course_sales ml-auto"><span /></div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Course */}
                <div className="col-lg-4 col-md-6">
                  <div className="course">
                    <div className="course_image"><img src={require("./common/chef.jpg")} alt="" /></div>
                    <div className="course_body">
                      <div className="course_header d-flex flex-row align-items-center justify-content-start">
                        <div className="course_tag"><Link to="/Python">Go To Course</Link></div>
                        <div className="course_price ml-auto"><span /></div>
                      </div>
                      <div className="course_title"><h3><Link to="/Python">Chef Automate</Link></h3></div>
                      <div className="course_text">Chef is a Configuration management tool that manages the infrastructure by writing code rather than using a manual process so that it can be automated, tested and deployed very easily. Chef has Client-server architecture and it supports multiple platforms like Windows, Ubuntu, Centos, and Solaris etc. It can also be integrated with cloud platform like AWS, Google Cloud Platform, and Open Stack etc.
                      </div>
                      <div className="course_footer d-flex align-items-center justify-content-start">
                        <div id="messages">
                        </div>
                        <div className="course_author_image"><img src alt="" /></div>
                        <div className="course_author_name"></div>
                        <div className="course_sales ml-auto"><span /></div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Course */}
                <div className="col-lg-4 col-md-6">
                  <div className="course">
                    <div className="course_image"><img src={require("./common/python.jpg")} alt="" /></div>
                    <div className="course_body">
                      <div className="course_header d-flex flex-row align-items-center justify-content-start">
                        <div className="course_tag"><Link to="/Python">Go To Course</Link></div>
                        <div className="course_price ml-auto"><span /></div>
                      </div>
                      <div className="course_title"><h3><Link to="/Python">Python For Developers</Link></h3></div>
                      <div className="course_text">Python language is incredibly easy to use and learn for new beginners and newcomers. The python language is one of the most accessible programming languages available because it has simplified syntax and not complicated, which gives more emphasis on natural language. Due to its ease of learning and usage, python codes can be easily written and executed much faster than other programming languages.
                      </div>
                      <div className="course_footer d-flex align-items-center justify-content-start">
                        <div id="messages">
                        </div>
                        <div className="course_author_image"><img src alt="" /></div>
                        <div className="course_author_name"></div>
                        <div className="course_sales ml-auto"><span /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>) : (
           <Redirect to={{ pathname: "/sign-in", 
             }} 
             />
        )
        );
    }
}

export default Courses;