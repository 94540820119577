import React from "react";
import { Switch, Route, HashRouter } from "react-router-dom";
import Login from "./components/login.component";
import SignUp from "./components/signup.component";
import Home from "./components/Home";
import Courses from "./components/Courses";
import Quiz from "./components/Quiz";
import Python from "./components/Python";
import Testaws from "./components/testaws";
import Aws from "./components/Aws";
import Aws1 from "./components/Aws1";
import AwsHome from "./components/AwsHome";
import Grades from "./components/Grades";
import Welcome from "./components/Welcome";
import ForgotPassword from "./components/ForgotPassword"
import ForgotPasswordVerification from "./components/ForgotPasswordVerification";
import ChangePasswordConfirmation from "./components/ChangePasswordConfirmation";
import NavBar from "./components/common/NavBar";
import { Auth } from 'aws-amplify';

class App extends React.Component {
  state = {
    isAuthenticated: false,
    isAuthenticating: true,
    user: null
  }

  setAuthStatus = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  setUser = user => {
    this.setState({ user: user });
  }

  async componentDidMount() {
    try {
      const session = await Auth.currentSession();
      this.setAuthStatus(true);
      console.log(session);
      const user = await Auth.currentAuthenticatedUser();
      this.setUser(user);
    } catch (error) {
      console.log(error);
    }
    this.setState({ isAuthenticating: false })
  }

  render() {
    const authProps = {
      isAuthenticated: this.state.isAuthenticated,
      user: this.state.user,
      setAuthStatus: this.setAuthStatus,
      setUser: this.setUser
    }
    return (
      !this.state.isAuthenticating &&
      <>
        <HashRouter>
          <NavBar auth={authProps} />
          <Switch>
            <Route exact path="/" render={(props) => <Home {...props} auth={authProps} />} />
            <Route path="/sign-in" render={(props) => <Login {...props} auth={authProps} />} />
            <Route path="/sign-up" render={(props) => <SignUp {...props} auth={authProps} />} />
            <Route path="/Home" render={(props) => <Home {...props} auth={authProps} />} />
            <Route path="/Courses" render={(props) => <Courses {...props} auth={authProps} />} />
            <Route path="/Aws1" render={(props) => <Aws1 {...props} auth={authProps} />} />
            <Route path="/Aws" render={(props) => <Aws {...props} auth={authProps} />} />
            <Route path="/AwsHome" render={(props) => <AwsHome {...props} auth={authProps} />} />
            <Route path="/Quiz" render={(props) => <Quiz {...props} auth={authProps} />} />
            <Route path="/Grades" render={(props) => <Grades {...props} auth={authProps} />} />
            <Route path="/Python" render={(props) => <Python {...props} auth={authProps} />} />
            <Route path="/Testaws" render={(props) => <Testaws {...props} auth={authProps} />} />
            <Route path="/Welcome" render={(props) => <Welcome {...props} auth={authProps} />} />
            <Route path="/ForgotPassword" render={(props) => <ForgotPassword {...props} auth={authProps} />} />
            <Route path="/ForgotPasswordVerification" render={(props) => <ForgotPasswordVerification {...props} auth={authProps} />} />
            <Route path="/ChangePasswordConfirmation" render={(props) => <ChangePasswordConfirmation {...props} auth={authProps} />} />
          </Switch>
        </HashRouter>
      </>
    );
  }
}
export default App;
