import React from 'react';
import { Redirect } from 'react-router-dom';
import './common/Video.css';
import "../../node_modules/video-react/dist/video-react.css";
import { Player, ControlBar, ReplayControl, ForwardControl, PlaybackRateMenuButton } from 'video-react';

class Aws1 extends React.Component {
    render () {
    return (
        this.props.auth.isAuthenticated ?
        (<div className="about">
            <div className="section_title text-center"><h2>Course: AWS Solutions Architect Second Session</h2></div>
        {/* <div className="container">
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6">
              <div className="about_content">
			  <Player
                playsInline
                preload
                aspectRatio="auto"
                poster={require('./common/cover2.png')}
                src='https://login.globalcloudsolutions.org/static/media/videos2/IamClass1.mp4'
                    >
      <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
                    </Player>

              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">Introduction to AWS Cloud/IAM Class 1</h4>
              </div>
              </div>   
              </div>
            </div>
            <div className="col-lg-6">
			<Player
            playsInline
            preload
            aspectRatio="auto"
            poster={require('./common/cover2.png')}
            src='https://login.globalcloudsolutions.org/static/media/videos2/IAMClass2.mp4'
            >
             <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
        </Player> 
        <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">IAM Class 2</h4>
              </div>
              </div>
		</div>
          </div>
        <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6">
              <div className="about_content">
			  <Player
                playsInline
                preload
                aspectRatio="auto"
                poster={require('./common/cover2.png')}
                src='https://login.globalcloudsolutions.org/static/media/videos2/IAMClass3.mp4'
                    >
      <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
                    </Player>

              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">IAM Class 3</h4>
              </div>
              </div>   
              </div>
            </div>
            <div className="col-lg-6">
			<Player
            playsInline
            preload
            aspectRatio="auto"
            poster={require('./common/cover2.png')}
            src='https://login.globalcloudsolutions.org/static/media/videos2/IAMClass4.mp4'
            >
             <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
        </Player> 
        <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">IAM Class 4</h4>
              </div>
              </div>
		</div>
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6">
              <div className="about_content">
			  <Player
                playsInline
                preload
                aspectRatio="auto"
                poster={require('./common/cover2.png')}
                src='https://login.globalcloudsolutions.org/static/media/videos2/iamclass5.mp4'
                    >
      <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
                    </Player>

              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">IAM Class 5</h4>
              </div>
              </div>   
              </div>
            </div>
      <div className="col-lg-6">
			<Player
            playsInline
            preload
            aspectRatio="auto"
            poster={require('./common/cover2.png')}
            src='https://login.globalcloudsolutions.org/static/media/videos2/IAMClass6.mp4'
            >
             <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
        </Player> 
        <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">Final IAM Class 6(Programatic Access/S3 Intro)</h4>
              </div>
              </div>
		</div> 
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6">
              <div className="about_content">
			  <Player
                playsInline
                preload
                aspectRatio="auto"
                poster={require('./common/cover3.png')}
                src='https://login.globalcloudsolutions.org/static/media/videos2/IAMClass7.mp4'
                    >
      <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
                    </Player>

              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">IAM Policy Assignment Review</h4>
              </div>
              </div>   
              </div>
            </div>
      <div className="col-lg-6">
			<Player
            playsInline
            preload
            aspectRatio="auto"
            poster={require('./common/cover3.png')}
            src='https://login.globalcloudsolutions.org/static/media/videos2/S3Class1.mp4'
            >
             <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
        </Player> 
        <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">S3 Class 2</h4>
              </div>
              </div>
		</div>
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6">
              <div className="about_content">
			  <Player
                playsInline
                preload
                aspectRatio="auto"
                poster={require('./common/cover3.png')}
                src='https://login.globalcloudsolutions.org/static/media/videos2/S3Class2.mp4'
                    >
      <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
                    </Player>

              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">Assignment Review </h4>
              </div>
              </div>   
              </div>
            </div>
      <div className="col-lg-6">
			<Player
            playsInline
            preload
            aspectRatio="auto"
            poster={require('./common/cover3.png')}
            src='https://login.globalcloudsolutions.org/static/media/videos2/S3Class3.mp4'
            >
             <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
        </Player> 
        <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">S3 Class 3/Bucket Policies</h4>
              </div>
              </div>
		</div> 
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6">
              <div className="about_content">
			  <Player
                playsInline
                preload
                aspectRatio="auto"
                poster={require('./common/cover3.png')}
                src='https://login.globalcloudsolutions.org/static/media/videos2/S3Class4.mp4'
                    >
      <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
                    </Player>

              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">S3 Class 4/Static Website & CloudFront</h4>
              </div>
              </div>   
              </div>
            </div>
      <div className="col-lg-6">
			<Player
            playsInline
            preload
            aspectRatio="auto"
            poster={require('./common/cover3.png')}
            src='https://login.globalcloudsolutions.org/static/media/videos2/EC2Class1.mp4'
            >
             <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
        </Player> 
        <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">Introduction to Virtual Machines/EC2 Class 1</h4>
              </div>
              </div>
		</div>
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6">
              <div className="about_content">
			  <Player
                playsInline
                preload
                aspectRatio="auto"
                poster={require('./common/cover3.png')}
                src='https://login.globalcloudsolutions.org/static/media/videos2/EC2Class2.mp4'
                    >
      <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
                    </Player>

              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">EC2 Class 2</h4>
              </div>
              </div>   
              </div>
            </div>
      <div className="col-lg-6">
			<Player
            playsInline
            preload
            aspectRatio="auto"
            poster={require('./common/cover3.png')}
            src='https://login.globalcloudsolutions.org/static/media/videos2/ec2class3.mp4'
            >
             <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
        </Player> 
        <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">EC2 Class 3</h4>
              </div>
              </div>
		</div>
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6">
              <div className="about_content">
			  <Player
                playsInline
                preload
                aspectRatio="auto"
                poster={require('./common/cover3.png')}
                src='https://login.globalcloudsolutions.org/static/media/videos2/EC2Class4.mp4'
                    >
      <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
                    </Player>

              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">EC2 Class 4 </h4>
              </div>
              </div>   
              </div>
            </div>
      <div className="col-lg-6">
			<Player
            playsInline
            preload
            aspectRatio="auto"
            poster={require('./common/cover3.png')}
            src='https://login.globalcloudsolutions.org/static/media/videos2/ec2class5.mp4'
            >
             <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
        </Player> 
        <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">EC2 Class 5</h4>
              </div>
              </div>
		</div>
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6">
              <div className="about_content">
			  <Player
                playsInline
                preload
                aspectRatio="auto"
                poster={require('./common/cover3.png')}
                src='https://login.globalcloudsolutions.org/static/media/videos2/ec2class6.mp4'
                    >
      <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
                    </Player>

              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">EC2 Class 6 </h4>
              </div>
              </div>   
              </div>
            </div>
      <div className="col-lg-6">
			<Player
            playsInline
            preload
            aspectRatio="auto"
            poster={require('./common/cover3.png')}
            src='https://login.globalcloudsolutions.org/static/media/videos2/EC2Class7.mp4'
            >
             <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
        </Player> 
        <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">EC2 Class 7</h4>
              </div>
              </div>
		</div>
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6">
              <div className="about_content">
			  <Player
                playsInline
                preload
                aspectRatio="auto"
                poster={require('./common/cover3.png')}
                src='https://login.globalcloudsolutions.org/static/media/videos2/EC2Class8.mp4'
                    >
      <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
                    </Player>

              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">EC2 Class 8 </h4>
              </div>
              </div>   
              </div>
            </div>
      <div className="col-lg-6">
			<Player
            playsInline
            preload
            aspectRatio="auto"
            poster={require('./common/cover3.png')}
            src='https://login.globalcloudsolutions.org/static/media/videos2/ec2class9.mp4'
            >
             <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
        </Player> 
        <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">Introduction to AWS Lambda</h4>
              </div>
              </div>
		</div>
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6">
              <div className="about_content">
			  <Player
                playsInline
                preload
                aspectRatio="auto"
                poster={require('./common/cover3.png')}
                src='https://login.globalcloudsolutions.org/static/media/videos2/vpcclass1.mp4'
                    >
      <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
                    </Player>

              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">VPC Class 1</h4>
              </div>
              </div>   
              </div>
            </div>
      <div className="col-lg-6">
			<Player
            playsInline
            preload
            aspectRatio="auto"
            poster={require('./common/cover3.png')}
            src='https://login.globalcloudsolutions.org/static/media/videos2/vpcclass2.mp4'
            >
             <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
        </Player> 
        <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">VPC Class 2</h4>
              </div>
              </div>
		</div>
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6">
              <div className="about_content">
			  <Player
                playsInline
                preload
                aspectRatio="auto"
                poster={require('./common/cover3.png')}
                src='https://login.globalcloudsolutions.org/static/media/videos2/vpcclass3.mp4'
                    >
      <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
                    </Player>

              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">VPC Class 3 (Bastion Host)</h4>
              </div>
              </div>   
              </div>
            </div>
      <div className="col-lg-6">
			<Player
            playsInline
            preload
            aspectRatio="auto"
            poster={require('./common/cover3.png')}
            src='https://login.globalcloudsolutions.org/static/media/videos2/vpcclass4.mp4'
            >
             <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
        </Player> 
        <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">VPC Class 4</h4>
              </div>
              </div>
		</div>
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6">
              <div className="about_content">
			  <Player
                playsInline
                preload
                aspectRatio="auto"
                poster={require('./common/cover3.png')}
                src='https://login.globalcloudsolutions.org/static/media/videos2/vpcclass5.mp4'
                    >
      <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
                    </Player>

              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">VPC Class 5 Route 53</h4>
              </div>
              </div>   
              </div>
            </div>
      <div className="col-lg-6">
			<Player
            playsInline
            preload
            aspectRatio="auto"
            poster={require('./common/cover3.png')}
            src='https://login.globalcloudsolutions.org/static/media/videos2/vpcclass6.mp4'
            >
             <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
        </Player> 
        <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">VPC Class 6 VPC Endpoints</h4>
              </div>
              </div>
		</div>
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6">
              <div className="about_content">
			  <Player
                playsInline
                preload
                aspectRatio="auto"
                poster={require('./common/cover3.png')}
                src='https://login.globalcloudsolutions.org/static/media/videos2/vpcclass7.mp4'
                    >
      <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
                    </Player>

              <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">VPC Class 7 Site to Site VPN</h4>
              </div>
              </div>   
              </div>
            </div>
      <div className="col-lg-6">
			<Player
            playsInline
            preload
            aspectRatio="auto"
            poster={require('./common/cover3.png')}
            src='https://login.globalcloudsolutions.org/static/media/videos2/vpcclass6.mp4'
            >
             <ControlBar autoHide={false}>
      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        <ReplayControl seconds={10} order={2.2} />
        <ForwardControl seconds={10} order={3.2} />
      </ControlBar>  
        </Player> 
        <div class="card bg-light">
              <div class="card-body">
                <h4 className="text-center font-weight-bold">VPC Class 6 VPC Endpoints</h4>
              </div>
              </div>
		</div>
          </div>
        </div> */}
      </div>
      ) : (
       <Redirect to={{ pathname: "/sign-in", 
         }} 
         />
    )
    );
}
}

export default Aws1;