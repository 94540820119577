import React from "react";
import { Link } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import '../components/common/about.css';


const wik = {
	display: 'block',
    position: "relative",
}


class Home extends React.Component {
    render () {
    return (
        this.props.auth.isAuthenticated ?
        (<div className="about">
        <div className="container">
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6" >
              <div className="about_content">
                <div className="about_title" >Welcome to RTCloud Solution Online Learning Portal</div>
                <h4>Our mission is simple: help students get the best out of Cutting-edge technology in cloud computing; specifically Amazon Web Services.</h4>
                <div className="about_text">
                  <h5>Class videos will be made available to watch on the portal 24 hours after each class, Click on Courses above to access the videos.</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-6" style={wik}>
              <div className="about_image">
              <div className="about_title">Class Schedules</div>
              <h3>AWS Solutions Architect Class</h3>
              <h4>Thursdays 9pm - 10pm CST</h4>
              <h4>Saturdays 10am - 11a CST</h4>
              <h4>Sundays 9pm - 10pm CST</h4>
              <h3>Python For Developers</h3>
              <h5>Using Lambda and Boto3</h5>
              <p>Coming Soon ...</p>
              </div>
            </div>
          </div>
          <div className="row about_row row-lg-eq-height">
            <div className="col-lg-6 order-lg-1 order-2">
              <div className="about_image"><img src="images/our_vision.jpg" alt="" /></div>
            </div>
            <div className="col-lg-6 order-lg-2 order-1">
              <div className="about_content">
                <div className="about_title">Recent Classes</div>
                <div className="card" style={{width: '18rem'}}>
                <img src={require("./common/AwsCloud.gif")} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title">AWS Solutions Architect Class</h5>
                  <p className="card-text"></p>
                  <Link to="/Aws1" className="btn-dark btn-block btn-lg">View Class</Link>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>) : (
           <Redirect to={{ pathname: "/sign-in", 
             }} 
             />
        )
    );
}
}

export default Home;