import React from 'react';
import { Redirect } from 'react-router-dom';
import './common/Video.css';
import data from "./common/data"

const Displaytitle = () => {
    return (
        <div className="card text-center">
        <div className="card-body">
            <h4 className="card-text">Course: AWS Solutions Architect Second Session</h4>    
        </div>
        </div>
      );
  }

const Displaybody = () => {
    return (
<div className="card text-center">
  <div className="card-header">
    <ul className="nav nav-tabs card-header-tabs">
        <button className="nav-link active" >Syllabus</button>
    </ul>
  </div>
  <div className="card-body text-left">
    <h5 className="card-title">Introduction/IAM</h5>
    <p className="card-text">{data.map( q => <li key={q.id}>{q.title} {q.duration}</li>)}</p>
  </div>
  <div className="card-body text-left">
    <h5 className="card-title">Storage S3</h5>
    <p className="card-text" >{data.map( q => <a href={q.link} key={q.id}>{q.title}{q.duration}</a>)}</p>
  </div>
</div>
    );
}

const Displaysidebar = () => {
    return (     
<div className="card text-white float-right bg-primary mb-3" style={{maxWidth: '18rem'}}>
  <div className="card-header">Header</div>
  <div className="card-body">
    <h5 className="card-title">Primary card title</h5>
    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
  </div>
</div>
    )
}

export default function Testaws() {
    return (
       <div className="container"> 
       <Displaytitle />
       <Displaybody />
       </div>
        );
}
