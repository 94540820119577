import React, { Component } from 'react';
import Validate from "../components/common/FormValidation";
import FormErrors from "../components/common/FormErrors";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import '../components/common/ForgotP.css';


class ForgotPasswordVerification extends Component {
    state = {
        verificationcode: "",
        email: "",
        newpassword: "",
        errors: {
          cognito: null,
          blankfield: false
        }
    }; 
    
    clearErrorState = () => {
        this.setState({
          errors: {
            cognito: null,
            blankfield: false
          }
        });
    };

    passwordVerificationHandler = async event => {
        event.preventDefault();
    
        // Form validation
        this.clearErrorState();
        const error = Validate(event, this.state);
        if (error) {
          this.setState({
            errors: { ...this.state.errors, ...error }
          });
        }
    
        try {
          await Auth.forgotPasswordSubmit(
            this.state.email,
            this.state.verificationcode,
            this.state.newpassword
          );
          this.props.history.push("/ChangePasswordConfirmation");
        }catch(error) {
          console.log(error);
        }
      };
    
    onInputChange = event => {
        this.setState({
          [event.target.id]: event.target.value
        });
        document.getElementById(event.target.id).classList.remove("is-danger");
      }; 
      
    render() {
        return (
          <section className="container-fluid" >
            <section className="row justify-content-center">
            <section className="col-12 col-sm-8 col-md-6">
            <div className="form-container">
                    <FormErrors formerrors={this.state.errors} />
                    <form onSubmit={this.passwordVerificationHandler}>
                        <h3>Set New Password</h3>
                        <p>Please enter the verification code sent to your email address below</p>
                        <p>Password Requirement, Minimun 8 Characters, One Special Character, One UpperCase Letter and One Number</p>
                        <div className="form-group">
                            <label>Verification Code</label>
                            <input type="text" className="form-control" id="verificationcode" placeholder="Enter Verification Code" value={this.state.verificationcode} onChange={this.onInputChange}/>
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input type="email" className="form-control" id="email" placeholder="Enter email" value={this.state.email} onChange={this.onInputChange}/>
                        </div>
                        <div className="form-group">
                            <label>New Password</label>
                            <input type="password" className="form-control" id="newpassword" placeholder="Enter New Password" value={this.state.newpassword} onChange={this.onInputChange}/>
                        </div>
                        <div className="form-group">
                            <label>Enter New Password Again</label>
                            <input type="password" className="form-control" id="confirmpassword" placeholder="Confirm New Password" />
                        </div>
                        <button type="submit" className="bt btn-dark btn-block btn-lg">Submit</button>
                        <p className="forgot-password text-right">
                        Already registered <Link to="/sign-in">log in?</Link>
                        </p>
                    </form>
                </div>
                </section>
                </section>
                </section>
                );
    }  

}

export default ForgotPasswordVerification;